
  .navbar-lg {
    background-color: var(--color-dark-blue);
    border-bottom: none;
    box-shadow: none;
  }

  #responsive-navbar-nav > div > div:last-child {
    flex-grow: 1;
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .tabs .link {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: none;
  }


  .navbar-expand .navbar-nav .nav-link {
    padding-left: calc(var(--bs-gutter-x) * .5);

  }

  .navbar-text {
    float: right;
    margin-top: 5px;

  }

  .tab-lg {
    background-color: var(--color-light-blue);
    border-bottom: none;
    box-shadow: none;
    width: 100%;    
    overflow: auto;
  }

  .tab-lg a {
    margin: 7px 0px 7px 0px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    width: 16.66%; /* Four equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
  }
  
  .navbar-band {
   font-weight: 700;
   color: white;

  }

  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0,.1);
  }

  .navbar-text a, .navbar-text a:focus, .navbar-text a:hover {
    color: white;
  }

  .navbar-brand:hover {
    color: var(--color-orange);
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: box-shadow .15s ease-in-out;
  }



  .linkSeparator {
    display:inline-block;
    height:2em; width:0.1em;
    background-color:white;
    margin-left:1.5em; 
  }

  .container-fluid {
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .nav-item {
    margin-left: 2rem;
  }
  .navbar-heading {
   position: absolute;
   margin-left: 10px;
   font-size: 1.5rem;
   margin-top: 5px;

  }

  .navbar>.container-fluid {
    justify-content: normal;
    display: inline;
    margin-top: 5px;
    z-index: 3;
  }

  img.img_logo {
    vertical-align: baseline;
  }
  

  .nav-link {
    display: inline;
  }

  button.navbar-toggler.collapsed{
    display: none;
}

.nav-pills .nav-link.active {
    background: transparent;
}

.navbar-light {
  color: white !important;
  font-weight: 400 !important;
}

@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .img_logo {
    width: 20% !important;
    margin-left: 10px !important;
  }
  .hn-header {
    padding-left: 3% ;
  }

  .tab-lg {
    display: block;
  }
  .navbar-toggler {
    float: right;
}

.btn-go-back {
  margin-left: 3%;
  margin-right: 3%;
}
}

@media only screen and (device-width: 912px) {
 /* For general Surface PRo layouts */
 .img_logo {
  width: 15% !important;
  margin-left: 10px !important;
}

.btn-go-back {
  margin-left: 3%;
  margin-right: 3%;
}

.hn-header {
  padding-left: 3% !important;
}
.tab-lg {
  display: block;
}
.navbar-toggler {
  float: right;
}
  }

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  .img_logo {
    width: 20% !important;
  }
  .tab-lg {
    display: block;
  }
  .navbar-toggler {
    float: right;
}
.btn-go-back {
  margin-left: 3%;
  margin-right: 3%;
  padding-right: 0 !important;
}
}
  

  @media (max-width: 990px) {
    .container-fluid {
        margin-left: 0%;
        margin-right: 0%;
      }
      button.navbar-toggler.collapsed {
    display: inline;
}
.navbar-light .navbar-toggler {
    display: inline;
    float: right;
}
.tab-lg {
  display: block;
}
div#responsive-navbar-nav.show  {
 
  margin-top: 10%;
}

.collapsed {
    margin-left: 85%;
}
  .img_logo {
    /* margin: 5px; // test  */
    position: absolute;
    width: 10rem;
    margin-top: 3rem;
  }
  .linkSeparator {
    display: none;
  }
  .navbar-heading {
    font-size: 1rem;
    margin-top: 1rem;
   }
   .navbar>.container-fluid {
    align-items: baseline;
   }
   .navbar-text {
   position: relative;
   margin-left:auto;


  }

  .collapse:not(.show){
    display: none !important;
  }

  

  div#responsive-navbar-nav.show > .container.container-fluid.navbar-nav.nav-pills{
    flex-direction: column;
    padding-left: 5px;
  }

  div#responsive-navbar-nav.show > .container.container-fluid.navbar-nav.nav-pills > a.nav-link.nav-link {
    width: auto;
    border-bottom: 0.5px solid #fff;
    border-radius: 1px;
  }

  .collapsed {
    display: inline;
}

  div#responsive-navbar-nav.show  {
 
    margin-top: 10%;
}

p:not(.fancy) {
    color: green;
  }
#logout:not(.collapsed) {
    display: inline;
    position: absolute;
    margin-left: 80%;
    margin-top: -54%;
}
}