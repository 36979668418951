.detail_info {
    padding-left: 0;
}

.coll_highlight {
  border: 2px solid var(--color-red);
}

.container.table-responsive.attachment {
  overflow: hidden;
}

.coll_highlight :focus {
  outline: none !important;
  border: 2px solid var(--color-red) !important;
}

.admitSourceId {
  margin-left: 2.4rem;
}

.downloadAttachment {
  color: var(--color-dark-blue) !important;
  font-weight: 400;
  cursor: pointer;
}

.alert-error {
  background-color: var(--color-red);
  color: white;
}

.span-form {
  margin-left: 5px;
}

.alert-success {
  color: white;
  background-color: var(--color-green);
}

.message-modal {
  margin-top: 1rem;
}

ul {
  color: var(--color-dark-blue);
}

.formEmailCollectorNotNeeded {
  margin-left: 10%;
}

#formEmailCollectorNotNeeded {
  margin-top: 30px;
}

.comment-textarea {
  margin-left: auto;
  width: auto;
}

.downloadAttachment:hover {
  color: var(--color-orange) !important;
  font-weight: 400;
}

input[type="file" i] {
  cursor: pointer;
  margin-top: 10px;

}

.heading-denial {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--color-dark-blue);
  padding-left: 8px;

}

.heading-denial-upload-attachment {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1rem;
  color: black;
  padding-left: 8px;

}

#save-new-file {
  margin-top: 10%;
  margin-left: -2%;
  width: max-content;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: capitalize;
}

.attachment {
  margin-top: 10%;
}

dl {
    padding-left: 10px;
    padding-right: 10px;
  }
  dt {
    /* float: left; */
    clear: left;
    width: auto;
    font-weight: bold;
    margin-right: 10px;
    display: inline;
  }
  dt::after {
    content: ":";
  }
  dd {
    
    /* margin: inherit;
    padding: 0 0 0.5em 0; */
  }

  .table-case-info {
    /* margin-right: 5px; */
  }

  .table-case-info table.table thead th  {
    color: white !important;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }

  .table-case-info table.table thead th {
    width: auto;
    font-size: 12px !important;
  }

  .table-case-info table.table tbody {
    border-style: hidden !important;
    background-color: #f2f2f2;
  }

  .table-case-info table.table tbody td {
    font-weight: bold;
    font-size: 13px;
  }

  .table-detail-info-case {
   padding-left: 10px;
   padding-right: 10px;
  }

  .row-case {
    margin-right: calc(-.5 * 1.5rem);
    margin-left: calc(-.5 * 1.5rem) !important ;
  }

  .table-div {
    background-color: var(--color-dark-blue) !important;
    border: none !important;  
  }

  .thead-detail {
    background-color: var(--color-dark-blue) !important;
    border: 1px solid (var(--color-dark-blue), 1.5)   !important; 
    color: white;
  }

  .table-responsive {
    /* overflow: auto; */
    padding-bottom: 1.5rem;
  }

  .appealLevel {
    margin-left: 2.7rem;
  }

  .unlinkelyToOverturn {
    margin-left: -7px;
    width: auto !important;
    margin-top: 5px;
  }

  .myfile {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    }

    .uploadAttachment {
        padding-left: 10%;
    }

    ul {
      overflow: auto;
    }

    .uploadAttachmentfile {
      padding-left: 10%;
      cursor: pointer;
      /* width: auto; */
    }

    .fileUpload {
        margin-top: 10px;
    }

  .emailTo {
    margin-left: 4.7rem;
  }

  .col-form-label {
    /* width: auto !important; */
    padding-left: 8px;
    font-size: 14px;
    font-weight: bold;
  }

  .col-sm-6 {
    padding: 0;
    width: 65%;
  }

  .screened_readonly {
    font-size: 12px !important;
    padding-left: 0 !important;
    margin-top: 2px;
    margin-left: -5px;
  }

  .screenedBy {
    position: absolute;
  }

  .screenedOn {
    float: right;
  }

  .screening_submit {
    width: auto;
    background-color: var(--color-dark-blue-button) !important;
    height: 15%;
    border-radius: 100px;
    text-decoration: none;
    font-weight: bold;
    text-transform: capitalize;
  }

  button.screening_submit.btn.btn-primary:active {
    background-color: var(--color-dark-blue-button) !important;
  }

  button.screening_submit.btn.btn-primary:focus {
    background-color: var(--color-dark-blue-button) !important;
    border: 1px solid var(--color-dark-blue-button-focus-border) !important;
  }

  button.screening_submit.btn.btn-primary:hover {
    background-color: var(--color-dark-blue-button-hover) !important;
  }

  button.screening_submit.btn.btn-primary:disabled {
    background-color: var(--color-dark-blue-button-disabled) !important;
  }


  .textarea {
    /* height: 130px; */
  }

  .col-sm-4 {
    font-size: 14px;
    width: auto;
  }

  .reason-denial {
    margin-left: 0.7rem
  }

  /* .col-form-label {
    margin-left: 10px;
  } */
  /* @media only screen and (max-width: 576px) {
    .col-sm-6 {
      width: 50% !important;
    }
  } */



  @media only screen and (width: 820px) and (height: 1180px)  {
    /* .table-responsive {
      overflow: auto !important;
    } */
    #save-new-file {
      position: relative !important;
    }

    .row {
      padding-left: 10px !important;
      padding-right: 10px !important;
      display: block;
    }
    .screening_submit {
      position:  relative !important;
      margin-left: 10px !important;
      margin-top: 10px !important;
    }
    .form-control {
      width: auto !important;
    }
    .col-sm-6 {
      margin-left: 0 !important;
      padding-left: none !important
    }
    #responsive-table > tbody > tr > td:last-child {
      width: 100% !important;
    }
    #responsive-table table, 
    #responsive-table thead, 
    #responsive-table tbody, 
    #responsive-table th, 
    #responsive-table td, 
    #responsive-table tr { 
      display: block; 
    }
    /* Hide table headers (but not display: none;, for accessibility) */
		#responsive-table thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#responsive-table tr { border: 1px solid #ccc; }

		#responsive-table td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			/* padding-left: 40%;  */
			white-space: normal;
			text-align:left;
			/* height: 6vh; */
		}

		#responsive-table td:before { 
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			color: #212529;
		}

			/*
	Label the data
	*/
	/* #responsive-table td:before { content: attr(data-title); } */


    	/*
	Label the data
	*/
	#responsive-table td:nth-of-type(1):before { content: "My Case:";font-weight: bold;display: block}
	#responsive-table td:nth-of-type(2):before { content: "FY:";font-weight: bold; display: block}
	#responsive-table td:nth-of-type(3):before { content: "Referral Date:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(4):before { content: "Patient Name:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(5):before { content: "Account:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(6):before { content: "Physician:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(7):before { content: "Financial Class:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(8):before { content: "Denial Reason:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(9):before { content: "Denied Amount:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(10):before { content: "Gross Charges:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(11):before { content: "Current Balance:"; font-weight: bold;display: block}

  th.sorting {
    font-size: 14px;
    font-weight: bold;
  }
  
  tbody + thead{
      display: none;
    }
  
  th.sorting_desc {
    font-size: 14px;
    font-weight: bold;
      /* padding-right: 0; */
  }

  #responsive-table {
    /* width: max-content; */
	margin-bottom: 0;
	width: 100%;
    max-width: 100%;
	font-size: 13px !important;
   
}

table th {
	font-size: 13px !important;
	font-weight: bold !important;
}
.referralDate {
  height: auto !important;
  padding: 0 !important;
}
  
  }


  @media only screen and (min-width: 600px)  and (max-width: 1200px) {
    /* .table-responsive {
      overflow: auto !important;
    } */

    .d-flex.row.justify-content-between {
      padding-left: 5rem !important;
    }

    .detail_info {
      width: auto;
    }

    #save-new-file {
      position: relative !important;
    }
    .row {
      /* padding-left: 10px !important; */
      padding-right: 10px !important;
      display: block;
    }
    .screening_submit {
      position:  relative !important;
      margin-left: 10px !important;
      margin-top: 10px !important;
    }
    .form-control {
      width: auto !important;
    }
    .col-sm-6 {
      margin-left: 0 !important;
      padding-left: none !important
    }
    #responsive-table > tbody > tr > td:last-child {
      width: 100% !important;
    }
    #responsive-table table, 
    #responsive-table thead, 
    #responsive-table tbody, 
    #responsive-table th, 
    #responsive-table td, 
    #responsive-table tr { 
      display: block; 
    }
    /* Hide table headers (but not display: none;, for accessibility) */
		#responsive-table thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#responsive-table tr { border: 1px solid #ccc; }

		#responsive-table td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			/* padding-left: 40%;  */
			white-space: normal;
			text-align:left;
			/* height: 6vh; */
		}

		#responsive-table td:before { 
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			color: #212529;
		}

			/*
	Label the data
	*/
	/* #responsive-table td:before { content: attr(data-title); } */


    	/*
	Label the data
	*/
	#responsive-table td:nth-of-type(1):before { content: "My Case:";font-weight: bold;display: block}
	#responsive-table td:nth-of-type(2):before { content: "FY:";font-weight: bold; display: block}
	#responsive-table td:nth-of-type(3):before { content: "Referral Date:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(4):before { content: "Patient Name:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(5):before { content: "Account:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(6):before { content: "Physician:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(7):before { content: "Financial Class:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(8):before { content: "Denial Reason:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(9):before { content: "Denied Amount:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(10):before { content: "Gross Charges:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(11):before { content: "Current Balance:"; font-weight: bold;display: block}

  th.sorting {
    font-size: 14px;
    font-weight: bold;
  }
  
  tbody + thead{
      display: none;
    }
  
  th.sorting_desc {
    font-size: 14px;
    font-weight: bold;
      /* padding-right: 0; */
  }

  #responsive-table {
    /* width: max-content; */
	margin-bottom: 0;
	width: 100%;
    max-width: 100%;
	font-size: 13px !important;
   
}

table th {
	font-size: 13px !important;
	font-weight: bold !important;
}
.referralDate {
  height: auto !important;
  padding: 0 !important;
}
  
  }


  @media (max-width: 600px) {

  .justify-content-md-center {
    width: 100% !important;
  }

  .uploadAttachmentfile {
    padding-left: 8px;
  }
  
  .comment-textarea {
    margin-left: 10px !important;
  }

  .formEmailCollectorNotNeeded {
    margin-left: 5px;
  }
  
  #formEmailCollectorNotNeeded {
    margin-top: 0;
  }

  .col-form-label {
    margin-left: -5px;
  }

  .subject {
    margin-left: -10px;
  }

  #formAdmitSource {
    margin-left: -17%;
  }

  .comment {
    margin-left: -10px !important;
  }

  .row-subject {
    display: block;
  }

  .uploadAttachment {
    padding-left: 0;
    margin-top: 10px;
  }

  #formAppealLevel {
    margin-left: -20%;
  }

  #formEmailTo {
    margin-left: -35%;
  }

  .comment {
    margin-left: -10px;
  }

  .reason-denial {
    margin-left: 0;
  }

  .row-appeals {
    display: block;
  }

  /* .form-appeal {
    margin-left: -15px;
  } */

  .table-detail-info-case {
    padding-left: 0;
    padding-right: 0;
  }

  .screening_submit {
   position: relative !important;
   margin-left: 7% !important; 
   margin-top: 5% !important;
  }

  .screenedOn {
    float: none;
  }

  .screenedBy {
    position: relative;
  }

  #save-new-file {
    position: relative;
    display: inline;
    margin-left: auto;
  }
  .detail_info {
    padding-left: 0;
    padding-right: 0;
}

  #responsive-table-case > tbody > tr > td:nth-of-type(3)::before{
    padding: 0.8rem,10px,0,4px !important;
    height: auto;
    }
    #responsive-table-case table, 
	#responsive-table-case thead, 
	#responsive-table-case tbody, 
	#responsive-table-case th, 
	#responsive-table-case td, 
	#responsive-table-case tr { 
		display: block; 
	}

    .detail_info_col {
        padding: 0;
        margin-top: 10px;
    }


    /* Hide table headers (but not display: none;, for accessibility) */
    #responsive-table-case thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#responsive-table-case tr { border: 1px solid #ccc; }

		#responsive-table-case  td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			/* padding-left: 40%;  */
			white-space: normal;
			text-align:left;
			/* height: 6vh; */
		}

		#responsive-table-case td:before { 
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			color: #212529;
		}


        #responsive-table-case td:nth-of-type(1):before { content: "Request Number:";font-weight: bold;display: block; color:'white'}
        #responsive-table-case td:nth-of-type(2):before { content: "Deficiency Date:";font-weight: bold; display: block; color:'white'}
        #responsive-table-case td:nth-of-type(3):before { content: "Deficiency Description:"; font-weight: bold;display: block; color:'white'}
        #responsive-table-case td:nth-of-type(4):before { content: "Collector Name:"; font-weight: bold;display: block; color:'white'}
        #responsive-table-case td:nth-of-type(5):before { content: "Deficiency Note:"; font-weight: bold;display: block;color:'white'}
}
  
  


 

