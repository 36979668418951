

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

  body {
    margin: 0;
    color:#000000;
    font-family: "Poppins" !important;
    src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', */
      /* 'Droid Sans', 'Helvetica Neue', sans-serif; */
      --color-dark-blue: #023f6b;
      --color-light-blue: #0D9DDB;
      --color-dark-blue-button: #023f6b;
      --color-dark-blue-button-hover: #0569b6;
      --color-dark-blue-button-focus-border: #023f6b;
      --color-dark-blue-button-disabled: #8fadc0;
      /* --color-light-blue: #0569b6; */
      --color-light-gray: #EEEEEE;
      --color-mid-gray: #A6A6A6;
      --color-dark-gray: #666;
      --color-purple: #805791;
      --color-red: #EA1D36;
      --color-orange: #FF7F00;
      --color-green: #4DAA50;
      --color-yellow: rgba(255, 255, 0, 0.5);
    -webkit-font-smoothing: antialiased;
  
    
    -moz-osx-font-smoothing: grayscale;
  }
  
  footer {
    position: absolute;
    bottom: auto;
    width: 100%;
    height: 2.5rem;
    text-align: center;
  }
  
  #root {
    padding-bottom: 2.5rem;
    position: relative;
    min-height: 93vh;
  }
  a {
    color: white;
    text-decoration: none;
  }
  th{
    color:#333333;
  }
  td{
    color:#333333;
  }
  
  .navbar-nav {
    --bs-nav-link-padding-y: 0px
  }

  .form-check-input:checked {
    background-color: var(--color-light-blue) !important;
    border-color: var(--color-light-blue);
  }


