.container {
  max-width: 1550px;
}


@media (min-width: 1280px){
.MuiContainer-maxWidthLg {
    max-width: 1220px !important;
}
}


@media (min-width: 1900px) {
  .container {
    max-width: 1870px;
   }

}


body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 400;
  
}


footer {
	position: absolute;
	bottom: auto;
	width: 100%;
	height: 2.5rem;
	text-align: center;
}

#root {
	padding-bottom: 2.5rem;
	position: relative;
	min-height: 91vh;
}
/* a {
	color: white;
} */

.navbar-nav .nav-item .nav-link {
  color: white;
  transition: none;
}

.spinner-grow {
	color: var(--color-dark-blue);
}

.navbar {
  --bs-navbar-padding-y: 0
}
