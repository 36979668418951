@import "mdbreact/dist/css/mdb.css";

svg.svg-inline--fa.fa-star {
	vertical-align: top !important;
}

@media only screen and (device-width: 768px) {
	/* For general iPad layouts */
	.search-bar {
		width: auto !important;
		margin-right: 20% !important;
	}
	.lookback-filter {
		padding: 0;
	}
	.d-flex.row.justify-content-between {
		padding-left: 5.5rem !important;
	}
	
	#responsive-table > tbody > tr > td:nth-of-type(3)::before{
		padding: 0.8rem,10px,0,4px !important;
		height: auto;
		}
		.referralDate {
			height: auto;
			padding: 0%;
		}

	
  }

  @media only screen and (device-width: 912px) {
	/* For general Surface Pro layouts */
	.search-bar {
		width: auto !important;
		margin-right: 20% !important;
	}
	.lookback-filter {
		padding: 0;
	}
	.d-flex.row.justify-content-between {
		padding-left: 5.5rem !important;
	}

	
  }


  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
	/* For portrait layouts only */
	.search-bar {
		width: auto !important;
		margin-right: 20% !important;
	}
	.lookback-filter {
		padding: 0;
	}
	.d-flex.row.justify-content-between {
		padding-left: auto !important;
	}
	
	#responsive-table > tbody > tr > td:nth-of-type(3)::before{
		padding: 0.8rem,10px,0,4px !important;
		height: auto;
		}
		.referralDate {
			height: auto;
			padding: 0%;
		}
  }

@media (max-width: 600px) {

	.dataTables_wrapper.dt-bootstrap4 {
		margin-top: 4rem !important;
	}

	a.page-link.page-link {
		font-size: .7rem;

	}
	.referralDate {
		height: auto !important;
		padding: 0 !important;
	}

	#responsive-table > tbody > tr > td:nth-of-type(3)::before{
		padding: 0.8rem,10px,0,4px !important;
		height: auto;
		}

		

	
	.pagination .page-item .page-link {
		font-size: .7rem !important;
	}

	#responsive-table > tbody > tr > td:last-child {
		width: 100% !important;
	}
	#responsive-table table, 
	#responsive-table thead, 
	#responsive-table tbody, 
	#responsive-table th, 
	#responsive-table td, 
	#responsive-table tr { 
		display: block; 
	}

	.dataTables_wrapper.dt-bootstrap4 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.row {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.col-sm-12 {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	
	.col-sm-5.col-lg-4.col-xl-6 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.col-sm-5.col-lg-4.col-xl-6 {
		width: 100%;
	}



		/* Hide table headers (but not display: none;, for accessibility) */
		#responsive-table thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#responsive-table tr { border: 1px solid #ccc; }

		#responsive-table td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			/* padding-left: 40%;  */
			white-space: normal;
			text-align:left;
			/* height: 6vh; */
		}

		#responsive-table td:before { 
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			color: #212529;
		}

			/*
	Label the data
	*/
	/* #responsive-table td:before { content: attr(data-title); } */


    	/*
	Label the data
	*/
	#responsive-table td:nth-of-type(1):before { content: "My Case:";font-weight: bold;display: block}
	#responsive-table td:nth-of-type(2):before { content: "FY:";font-weight: bold; display: block}
	#responsive-table td:nth-of-type(3):before { content: "Referral Date:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(4):before { content: "Patient Name:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(5):before { content: "Account:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(6):before { content: "Physician:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(7):before { content: "Financial Class:"; font-weight: bold;display: block}
	#responsive-table[data-route="4"] td:nth-of-type(8):before
	{ 
		
		content: "Screened Date";
		font-weight: bold;
		display: block
	}
	#responsive-table td:nth-of-type(8):before
	{ 
		
		content:"Denial Reason:";
		font-weight: bold;
		display: block
	}
	#responsive-table td:nth-of-type(9):before { content: "Denied Amount:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(10):before { content: "Gross Charges:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(11):before { content: "Current Balance:"; font-weight: bold;display: block}
}

th.sorting {
	font-size: 14px;
	font-weight: bold;
}

tbody + thead{
    display: none;
  }

th.sorting_desc {
	font-size: 14px;
	font-weight: bold;
    /* padding-right: 0; */
}

#table-text-referrals {
    color: var(--color-light-blue);
    font-weight: 700;
    cursor: pointer;
}
#table-text-referrals:hover {
    color: var(--color-dark-blue-button-hover);
}
#table-text-referrals:focus {
    color: var(--color-dark-blue-button-hover);
	border: 1px solid var(--color-dark-blue-button-focus-border);
}


.referralDate {
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
	filter: brightness(1.2);
	color: black;
}



/* table.dataTable thead .sorting_desc:after {
	font-size: 10px;
	right: 16px;
	font-weight: 500;
} */


#responsive-table {
    /* width: max-content; */
	margin-bottom: 0;
	width: 100%;
    max-width: 100%;
	font-size: 13px !important;
   
}

table th {
	font-size: 13px !important;
	font-weight: bold !important;
}

.dataTables_length.bs-select {
	display: none;
}

.lookback-filter {
	padding: 0;
}

.dataTables_wrapper.dt-bootstrap4 {
    margin-left: 10%;
    margin-right: 10%;
}

#responsive-table  th{
    /* width: 50px; */
    width: auto!important;

  }

#responsive-table > th {
	font-weight: bold;

}
#responsive-table > tbody > tr > th {
	padding: 10px !important;
	font-size: 13px;
	font-weight: 700;
}
td.displayFacultyList {
	font-size: 14px;
	font-weight: 400px;
}



#responsive-table > tbody > tr > td:nth-of-type(3){
padding: 0 !important ;
margin: auto !important;
height: 45.09px ;
}
#responsive-table > tbody > tr > td {
	/* padding: 10px !important; */
	font-size: 13px;
	font-weight:600;
	color: #333333;
	padding-top: 0.8rem !important;
	padding-bottom: 0 !important;
	padding-right: 10px !important;
	/* padding-left: 4px !important; */
}

.star-icon {
    font-size: 1.2rem;
	cursor: pointer;
}

.btn:hover {
	background-color: RoyalBlue;
  }


#responsive-table > tbody > tr > td:last-child {
	width: 100px;
}
#responsive-table>:not(:last-child)>:last-child>* {
	border-bottom-color: #eee;
}

#responsive-table>:not(:last-child)>:last-child>th {
	border-bottom-color: #eee;
	font-weight: bold;
   
}

.dataTables_info {
	font-size: 12px;
}
.dataTables_length > label {
	font-size: 13px;
	color: #333333 !important;
}
select.custom-select.custom-select-sm.form-control.form-control-sm {
	color: #333333 !important;
}
.dataTables_length > select {
	font-size: 13px;
}
.pagination .page-item.active .page-link:hover {
	background-color: var(--color-light-blue) !important;
}
.pagination .page-item.active .page-link {
	background-color: var(--color-light-blue) !important;

}

.row > .col-sm-12.col-md-5 {
	display: none;
}

.row >.col-sm-12.col-md-7 {
	width: 100%;
}

.pagination {
	justify-content: center !important; 
}

.row {
	margin-top: 0 !important;
	/* margin-right: 0 !important; */
	margin-left: 0 !important;
}

.search-bar {
	margin-right: 25%;
}

.row-container {
	margin-top: 1.5rem !important;
	width: 100%
}


  

 .link-btn-display {
	/* height: 3rem;
    margin-left: 2%; */
    background-color: white !important;
    border-radius: 100px;
    color: var(--color-dark-blue) !important;
    text-decoration: none;
	border: 2px solid #EEEEEE;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
/* 
	border: 1px solid rgb(9,157,219);
    background-color: rgb(9,157,219);
    color: white !important; */
    /* padding: 2px;
	border-radius: 2px; */
}

/* tr td:last-child {
	vertical-align: middle !important;
} */

table.dataTable thead .sorting:before {
	/* bottom: .5em !important; */
    /* padding-right: 30% !important; */
	margin-right: auto !important;
    
}
table.dataTable thead .sorting:after {
	/* bottom: .5em !important; */
	margin-right: auto !important;

    /* padding-right: 30% !important; */

}
table.dataTable thead .sorting_asc:after {
	/* bottom: .5em !important; */
    /* padding-right: 30% !important; */
	margin-right: auto !important;


}
table.dataTable thead .sorting_asc:before {
	/* bottom: .5em !important; */
    /* padding-right: 30% !important; */
	margin-right: auto !important;


}
table.dataTable thead .sorting_desc:after {
	/* bottom: .5em !important; */
    /* padding-right: 30% !important; */
	margin-right: auto !important;

}
table.dataTable thead .sorting_desc:before {
	/* bottom: .5em !important; */
    /* padding-right: 30% !important; */
	margin-right: auto !important;


}
thead {
	border: 1px solid #f2f2f2 !important;
    background-color: var(--color-light-gray) !important;
	vertical-align: middle !important;
}
.table-responsive {
		border: 1px solid #EEEEEE !important;
        background-color: #f2f2f2;
        padding-left: 5px;
        padding-right: 5px;
		overflow: auto;
        
}

.error {
	color: #ea1d36;
	padding-top: 10px;
	padding-left: 50%;
  }
