.hn-header {
    font-size: 1.7rem;
    font-weight: bold;
    color: var(--color-dark-blue);
    padding-top: 1.2rem;
    padding-left: 10rem;
}
.home {
    height: 5rem;
    background-color: #f2f2f2;
}

@media (max-width: 600px) {
.hn-header {
    padding-left: 10px;
}
.btn-go-back {
    padding-right: 1rem !important;
}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
	/* For portrait layouts only */
    .hn-header {
        padding-left: 10% !important;
    }
  }