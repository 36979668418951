#responsive-table-users > tbody > tr > td:nth-of-type(3) {
    padding: auto !important;
}

#responsive-table-users {
    /* width: max-content; */
	margin-bottom: 0;
	width: 100%;
    max-width: 100%;
	font-size: 13px !important;
   
}

#responsive-table-users  th{
    /* width: 50px; */
    width: auto!important;

  }

#responsive-table-users > th {
	font-weight: bold;

}
#responsive-table-users > tbody > tr > th {
	padding: 10px !important;
	font-size: 13px;
	font-weight: 700;
}


table th {
	font-size: 13px !important;
	font-weight: bold !important;
}

#responsive-table-users > tbody > tr > th {
	padding: 10px !important;
	font-size: 13px;
	font-weight: 700;
}
#responsive-table-users > tbody > tr > td {
	padding: 10px !important;
	font-size: 13px;
	font-weight:600;
	color: #333333;
	
}

.MuiContainer-root .col-form-label {
    font-size: 1rem;
}

.new-back {
    float: right;
    margin-top: -3%;
    color: var(--color-dark-blue);
	font-weight: bold;
}

.new-back:hover{
    color: var(--color-dark-blue-button-hover);
}
.new-back:focus{
    color: var(--color-dark-blue-button-hover);
}

.heading-home {
    padding-left: 0 !important;
}

.MuiContainer-root .form-control {
    width: 60%;
}
.alert-success {
	color: white;
	background-color: var(--color-green);
  }

.form-user {
	margin-top: 2rem;
}

.create-btn {
    height: auto !important;
    margin-left: 5% !important;
}

.form {
    margin-top: 40px;
}

.MuiContainer-root .row {
    margin-bottom: 20px;
}
.hr {
    margin-top: 20px;
  }

@media (max-width: 600px) {

	.col-6 {
		width: auto;
	}

	.mdb-datatable-filter.flex-row {
		width: 70% !important;
	}

	.col-sm-12.col-md-6 {
		display: inline !important;
	}

	.create-new-users {
		margin-top: 30px;
		margin-left: -20px;
	}

	.create-btn {
		margin-left: 0 !important;
	}

	.new-back {
		float: none;
	}

	.MuiContainer-root .row {
		display: block;
	}

	.MuiContainer-root .form-control {
		width: 100%;
	}
    #responsive-table-users > tbody > tr > td:nth-of-type(3)::before{
		padding: 0.8rem,10px,0,4px !important;
		height: auto;
		}

		

	
	.pagination .page-item .page-link {
		font-size: .7rem !important;
	}

	#responsive-table-users > tbody > tr > td:last-child {
		width: 100% !important;
	}
	#responsive-table-users table, 
	#responsive-table-users thead, 
	#responsive-table-users tbody, 
	#responsive-table-users th, 
	#responsive-table-users td, 
	#responsive-table-users tr { 
		display: block; 
	}

    /* Hide table headers (but not display: none;, for accessibility) */
		#responsive-table-users thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#responsive-table-users tr { border: 1px solid #ccc; }

		#responsive-table-users td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			/* padding-left: 40%;  */
			white-space: normal;
			text-align:left;
			/* height: 6vh; */
		}

		#responsive-table-users td:before { 
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			color: #212529;
		}

			/*
	Label the data
	*/
	/* #responsive-table td:before { content: attr(data-title); } */


    	/*
	Label the data
	*/
	#responsive-table-users td:nth-of-type(1):before { content: "Last Name:";font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(2):before { content: "First Name:";font-weight: bold; display: block}
	#responsive-table-users td:nth-of-type(3):before { content: "Email:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(4):before { content: "Type:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(5):before { content: "Active Record:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(6):before { content: "Created Date:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(7):before { content: "Created By:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(8):before { content: "Modified Date:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(9):before { content: "Modified By:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(10):before { content: "Admin:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(11):before { content: ""; font-weight: bold;display: block}
}


@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
	.mdb-datatable-filter .form-control{
		width: 100% !important;
		margin-left: 15px;
	}

	.new-back {
		margin-top: -5%;
	}
	
	.col-6 {
		width: auto;
	}

	.mdb-datatable-filter.flex-row {
		width: 70% !important;
	}

	.col-sm-12.col-md-6 {
		display: inline !important;
	}

	.create-new-users {
		/* margin-top: 30px;
		margin-left: -20px; */
	}

	.hn-header {
		padding-left: 0 !important;
	}

	.create-new-users {
		position: relative !important;
	}

	.create-btn {
		margin-left: 0 !important;
	}

	.new-back {
		float: right;
	}

	/* .MuiContainer-root .row {
		display: flex !important;
	} */
	input.form_control.form-control {
		width: 100% !important;
	}

	.MuiContainer-root .form-control {
		width: 100%;
	}
    #responsive-table-users > tbody > tr > td:nth-of-type(3)::before{
		padding: 0.8rem,10px,0,4px !important;
		height: auto;
		}

		

	
	.pagination .page-item .page-link {
		font-size: .7rem !important;
	}

	#responsive-table-users > tbody > tr > td:last-child {
		width: 100% !important;
	}
	#responsive-table-users table, 
	#responsive-table-users thead, 
	#responsive-table-users tbody, 
	#responsive-table-users th, 
	#responsive-table-users td, 
	#responsive-table-users tr { 
		display: block; 
	}

    /* Hide table headers (but not display: none;, for accessibility) */
		#responsive-table-users thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#responsive-table-users tr { border: 1px solid #ccc; }

		#responsive-table-users td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			/* padding-left: 40%;  */
			white-space: normal;
			text-align:left;
			/* height: 6vh; */
		}

		#responsive-table-users td:before { 
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			color: #212529;
		}

			/*
	Label the data
	*/
	/* #responsive-table td:before { content: attr(data-title); } */


    	/*
	Label the data
	*/
	#responsive-table-users td:nth-of-type(1):before { content: "Last Name:";font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(2):before { content: "First Name:";font-weight: bold; display: block}
	#responsive-table-users td:nth-of-type(3):before { content: "Email:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(4):before { content: "Type:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(5):before { content: "Active Record:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(6):before { content: "Created Date:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(7):before { content: "Created By:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(8):before { content: "Modified Date:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(9):before { content: "Modified By:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(10):before { content: "Admin:"; font-weight: bold;display: block}
	#responsive-table-users td:nth-of-type(11):before { content: ""; font-weight: bold;display: block}
}


