.button-search {
    height: 2.5rem;
    margin-top: auto !important;
    
}

.mdb-datatable-filter.flex-row {
    width: 50% !important;
}

.create-new-users {
    position: absolute;
    width: auto;
    color: var(--color-dark-blue);
    font-weight: bold;
    margin-top: 10px;
}
.create-new-users:hover {
    color: var(--color-light-blue);
}
.new {
    float: right;
    font-size: 1rem;
    padding-top: .5rem;
    color: var(--color-dark-blue) !important;
}
.new:hover{
    color: var(--color-dark-blue-button-hover) !important;
}
.new:focus{
    color: var(--color-dark-blue-button-hover) !important;
}

button.button-search.btn.btn-primary {
    background-color: white !important;
    position: absolute !important;
    margin-left: 100% !important;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-left-color: none !important;
  
}

.fa-search {
    color: gray !important;
    display: block !important;
}

button.button-search.btn.btn-primary:hover {
    background-color: #f2f2f2 !important;

}

@media (max-width: 600px) { 
button.button-search.btn.btn-primary {
    position:  relative !important;
    margin-left: auto !important;
}
}